import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngDefiStakingDevelopment = loadable(() => import("../components/DefiStakingDevelopment/english.js"));
const ArabicDefiStakingDevelopment = loadable(() => import("../components/DefiStakingDevelopment/arabic.js"));
const ChineseDefiStakingDevelopment = loadable(() => import("../components/DefiStakingDevelopment/chinese.js"));

const schema = {
    "@context": "http://schema.org/",
    "@graph": [
        {
            "@type": "product",
            "image": "https://softtik.com/images/nft-collection/invest-section-21.webp",
            "name": "Leading DeFi Staking Platform Development Services",
            "description": "Softtik offers stellar DeFi staking platform development services through a professional team with in-depth knowledge and experience.",
            "brand": "Softtik Technologies",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.7",
                "reviewCount": "124"
            }
        },
        {
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "What is Decentralized Staking?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Decentralized Staking is locking or holding crypto assets to maintain the Proof of Stake (PoS) continuously enabled blockchain operations. Holders get rewards in exchange for locking their digital assets.</p>"
                }
            }, {
                "@type": "Question",
                "name": "What is Defi staking in Binance?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Staking allows you to stake your coins in exchange for rewards in the DeFi space. You can stake your coins in a liquidity pool to provide liquidity or a protocol's reward pool. Depending on your risk tolerance, DeFi staking offers many opportunities.</p>"
                }
            }, {
                "@type": "Question",
                "name": "Can you lose money staking on Binance?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>The Crypto market is associated with risks and volatility. For example, if you’re staking an asset for 15% APY (Annual Percentage Yield), that asset plunges 50% in value. So in such a case, you might lose money. However, the chances of happening such a thing are rare.</p>"
                }
            }
            ]
        }
    ]
}

export class DefiStakingDevelopment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "English"
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');

        if (region == 'ae') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;
        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/defi-staking-platform-development-services/"
                        title="DeFi Staking Platform Development Services - Softtik"
                        thumbnail="https://softtik.com/images/nft-collection/invest-section-21.webp"
                        description="Softtik offers stellar DeFi staking platform development services through a professional team with in-depth knowledge and experience. "
                    />
                    <main>
                        <div id="wrapper">
                            <div className={`collection-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicDefiStakingDevelopment />
                                    : lang == "China"
                                        ? <ChineseDefiStakingDevelopment />
                                        : <EngDefiStakingDevelopment />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default DefiStakingDevelopment;